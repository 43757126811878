import React, { useEffect, useState } from 'react'
import styled  from 'styled-components'
import { Btn, Title } from "../components/styles";
import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
function Events(props) {
    const token=useSelector(state=>state.token)
    const [data,setData]=useState([])
    useEffect(()=>{
        fetch(`${host}/api/events/all`,{
            method:"get"
        }).then(res=>res.json()).then(data=>{
            // console.log(data)
            setData(data.data)
        })
    })
    return (
        <Container>
            <Title style={{color:'red'}}>
                Events
            </Title>
            <Btn onClick={()=>{props.history.push('/addevent')}} style={{marginRight:"10px"}}>
                Add New
            </Btn>
            {/* <SearchBox>

            </SearchBox> */}
            <EventsContainer>
            {
                data.map((item,key)=>(
                    <Event className={item.status} key={key} onClick={()=>props.history.push(`/editevent/${item._id}`)}>
                <Logo>
                    <img src={item.logo} onError={e=>{e.target.style.display="none"}}/>
                </Logo>
                <EventName>{item.name}</EventName>
            </Event>
            
                ))
            }
            
            </EventsContainer>

        </Container>
    )
}

export default Events


const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

const EventsContainer=styled.div`
width: 100%;
padding: 10px 20px;
display: flex;
flex-direction:column;
flex: 1;
/* align-items: center; */

`
const Event=styled.div`
/* padding:5px 10px; */
display:flex;
/* width:580px; */
width: 100%;
height:50px;
/* background:${props=>props.bg?props.bg:"#33bf44"}; */

cursor:pointer;
border: 1px solid;
box-shadow:2px 2px 5px 0 rgba(0,250,0,0.5);
&.completed{
    background:red;
    :hover{
        background:tomato; 
    }
}
&.upcoming{
    background:blue;
    :hover{
        background:rgb(4,10,200);
    }
}
&.ongoing{
    background:green;
    :hover{
        background:lightgreen;
    }
}
`
const Logo=styled.div`
padding:1px;
width:50px;
height:50px;
display:flex;
align-items:center;
justify-content: center;
/* height:auto; */
img{
    display:block;
    height:76%;
}
`
const EventName=styled.div`
padding:5px 4px;
color:white;
display:flex;
align-items: center;
font-size:18px;
font-weight: 600;
`
