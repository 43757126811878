import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Btn, Title } from '../components/styles';
import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
import { logout, snackmsg } from '../actions/action';
function About() {
    const [about,setAbout]=useState('')
    const [loading,setLoading]=useState(true)
    const token=useSelector(state=>state.token)
    const dispatch=useDispatch()
    useEffect(()=>{
        fetch(`${host}/api/about`,{
            method:'get'
        }).then(async(res)=>{
           if(res.status==200){

               const response=await res.json()
               setAbout(response.data)
           }
           else{
               
           }
           setLoading(false)
          
            
        })

    },[])
    const submithandler=()=>{
        
        fetch(`${host}/admin71/aboutus`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
                ,authorization:`Bearer ${token}`
            },
            body:JSON.stringify({
                about:about
            })
        }).then(res=>{
            if(res.status==200){
                dispatch(snackmsg("Updated Sucess"))

            }
            else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
            }
        }).catch(err=>{
            dispatch(snackmsg("Network Error"))
        })

    }
    return (
        <Container>
            <Title style={{color:"red"}}>
                About Us

            </Title>
           {!loading &&<> <TextContainer>

            <TextBox rows="25" value={about} onChange={e=>setAbout(e.target.value)}>
            </TextBox>
            </TextContainer>
            <Btn onClick={submithandler}>
                Update
            </Btn></>}
        </Container>
    )
}

export default About
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  
`;
const TextContainer=styled.div`
margin-top: 24px;
width: 100%;
display: flex;
justify-content: center;
margin-bottom: 20px;
`
const TextBox=styled.textarea`
width: 70%;
outline: none;
font-size: 16px;

`