import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function App(props) {
  const editorRef = useRef(null);

 
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor apiKey="9j3bnzd628qziqaicjidu8q3eb30kklygnivik7egjnhtt9e"
        onInit={(evt, editor) => editorRef.current = editor}
        value={props.currentValue}
        //  initialValue={props.currentValue}
        onEditorChange={e=>{props.onChange(e)}}
        init={{
          height: props.height?props.height:330,
          menubar: false,
          plugins: [
            "advlist autolink link  lists charmap print preview hr anchor pagebreak spellchecker link",
            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking",
            "save table contextmenu directionality emoticons template paste textcolor",
        ],
        toolbar: "insertfile undo redo | fontselect | fontsizeselect| link  styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | l      ink image | print preview media fullpage | forecolor backcolor emoticons",
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          fontsize_formats:
    "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt"
        }}
      />

    </>
  );
}