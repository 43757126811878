import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Btn,Title} from '../components/styles';
import { Modal, TextField } from '@material-ui/core';
import * as Icon from '@fortawesome/free-solid-svg-icons' 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useSelector,useDispatch} from 'react-redux'
import { logout, snackmsg } from '../actions/action';
import {host} from '../constant'
let icons=Object.keys(Icon)

function WhatWeDo() {
    const dispatch=useDispatch()
    const [open,setOpen]=useState(false)
    const token=useSelector(state=>state.token)

    const [selectedIcon,setSelectedIcon]=useState("")
    const [title,setTitle]=useState("")
    const [description,setDescription]=useState("")
    const [data,setData]=useState([])
    const [type,setType]=useState({
        method:"get",
        url:"",
        title:"Add",
        btntext:"Hello"
    })
    useEffect(()=>{
        fetch(`${host}/api/wwd`,{
            method:"get"
        }).then(res=>res.json()).then(result=>{
            setData(result.data)
        })

    },[])
    // const [valid,setValid]=useState(false)
    const submitHandler=()=>{
        if(!title.trim() || !description.trim() ||icons.indexOf(selectedIcon)<0){
            return dispatch(snackmsg("All Fields are required"))
        }
        var body={
            title:title,
            description:description,
            icon:selectedIcon
        }
        fetch(`${host}${type.url}`,{
            method:type.method,
            headers:{
                "Content-Type":'application/json',
                authorization:`Bearer ${token} `
            },
            body:JSON.stringify(body)
            
        }).then(async (res)=>{
            if(res.status==200){
                dispatch(snackmsg("Updated Sucess"))
                const response=await res.json()
                if(type.method=="post"){
                    setData(data=>[...data,response.data])
                }
                else{
                    setData(data=>data.map(item=>{
                        return item._id==response.data._id?response.data:item
                    }))

                }
                setOpen(false)
            

            }else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
            }
        }).catch(err=>{
            dispatch(snackmsg("Network Error"))

        })
    }
    const deleteHandler=(item)=>{
        if(window.confirm("Do you want to delete?")){
            fetch(`${host}/admin71/deletewwd/${item._id}`,{
                method:"delete",
                headers:{
                    "Content-Type":'application/json',
                    authorization:`Bearer ${token} `
                }
            }).then(res=>{
                if(res.status==200){
                    dispatch(snackmsg("Delete Sucess"))
                    setData(data=>data.filter(dat=>{
                        return dat._id!=item._id
                    }))
                    
                    // window.location.reload(false)
                    
                }else if(res.status==401){
                    dispatch(snackmsg("Session Expired"))
                    dispatch(logout())
                    
                }
                // setOpen(false)
            }).catch(err=>{
                dispatch(snackmsg("Network Error"))
    
            })
        }

    }
    const addbtnhandler=()=>{
        setType({
            method:"post",
            url:"/admin71/addwwd",
            title:"Add What We Do",
            btntext:"+Add"

        })
        setTitle('')
        setSelectedIcon('')
        setDescription('')
        setOpen(true)
    }
    const editBtn=(item)=>{
        setType({
            method:"put",
            url:`/admin71/editwwd/${item._id}`,
            title:"Edit What We Do",
            btntext:"Edit"

        })
        setTitle(item.title)
        setDescription(item.description)
        setSelectedIcon(item.icon)
        setOpen(true)

    }
    return (
        <Container>
            <Title style={{color:'red'}}>What We Do</Title>
            <Btn style={{marginRight:"3px"}} onClick={addbtnhandler}>Add New+</Btn>
            <WWDContainer>
                {
                    data.map((item,key)=>(
                        <WWD key={key} >
                    <div onClick={()=>editBtn(item)}>{item.title}</div>
                    <DeleteIcon onClick={()=>deleteHandler(item)}/>

                </WWD>
                    )

                    )
                }
                

            </WWDContainer>
            <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
       open={open}
       onClose={()=>setOpen(false)}
      >
        <Card style={{ outline: "none" }}>
     <CardTitle>
       {type.title}
     </CardTitle>
    <Form>
        <InputContainer>
        <Label>Title</Label>
        <input value={title} onChange={e=>setTitle(e.target.value)} type="text" required/>
        </InputContainer>
        <InputContainer>
        <Label>Description</Label>
        <textarea value={description} onChange={e=>setDescription(e.target.value)} rows="10" required/>
        </InputContainer>
        <InputContainer>
        
 
    
    
        <Label>
            Icon
        </Label>
        <Autocomplete
    
      options={icons}
      getOptionLabel={(option) => option}
      onChange={(e,value)=>{setSelectedIcon(value)}}
  
      renderInput={(params) => <TextField   style={{borderBottom:"none"}}  {...params}  />}
    />
  
{
    icons.indexOf(selectedIcon)>=0&&
<FontAwesomeIcon icon={Icon[selectedIcon]} style={{height:"50px",width:"100%",marginTop:"10px"}} />
}

        </InputContainer>
        <Btn style={{marginTop:"10px"}} onClick={submitHandler}>
           {type.btntext}
        </Btn>
    </Form>
        </Card>
        </Modal>
         
        </Container>
    )
}
const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */

  /* gap:10px; */
`;
const InputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width:${(props) => (props.width ? props.width : "100%")}; */
  input {
    /* flex: 1; */
    outline: none;
    width: 100%;
    padding: 10px 8px;
    /* border:none; */
    font-size: 15px;
    border-bottom: 1px solid black;
    border-radius: 12px;
  }
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4px;
  color: red;
  font-weight: 600;
  font-size: 15px;
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const Card = styled.div`
  outline: none;
  padding: 14px 15px;
  background: white;
  /* width: 80%; */
  width: 500px;
  border-radius: 10px;
  max-height: 90%;
  overflow-y: auto;
`

const WWDContainer=styled.div`
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
`
const CardTitle=styled.h2`
width:100%;
text-align:center;
`
const WWD=styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width:90%;
height: 40px;
color:white;
font-size: 16px;
cursor:pointer;
padding: 0px 6px;
border-bottom: 2px solid white;
background: red;
:hover{
    background: tomato;
}
`
export default WhatWeDo
