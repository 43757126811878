import { host } from "../constant"
export const CHECK_SUCESS="CHECK_SUCESS"
export const CHECK_FAIL="CHECK_FAIL"
export const LOGOUT="LOGOUT"
export const LOGIN="LOGIN"
export const OPENSNACK="OPENSNACK"
export const CLOSESNACK="CLOSESNACK"
export const MESSAGE="MESSAGE"
export const check=()=>async(dispatch,getState)=>{
const token=await JSON.parse(localStorage.getItem('token'))

if(!token) return dispatch({
    type:CHECK_FAIL
})
   var response= await fetch(`${host}/admin71/check`,{ 
        method:'get',
        headers:{
          authorization:`Bearer ${token.authtoken}`
        }
      });
    if(response.status==200){
        return dispatch({
        type:CHECK_SUCESS,
        token:token.authtoken
        })
    }
    return dispatch({
        type:CHECK_FAIL
    })
    
}
export const logout= ()=>{
     localStorage.removeItem('token')
    return {
        type:LOGOUT
    }
}
export const login=(token)=>{
    return{
        type:LOGIN,
        token:token
    }
}
export const closesnack=()=>{
    return {
        type:CLOSESNACK
    }
}
export const opensnack=()=>{
    return {
        type:OPENSNACK
    }
}
export const snackmsg=(msg)=>{
    return async(dispatch)=>{
      dispatch({
            type:MESSAGE,
            message:msg
        })
      dispatch({
          type:OPENSNACK
      })
    
    } 
}