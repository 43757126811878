import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Btn, Title } from "../components/styles";
import EventIcon from "@material-ui/icons/Event";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
import { logout, snackmsg } from "../actions/action";
function Data(props) {
  const dispatch=useDispatch()
  const token=useSelector(state=>state.token)
  const [event,setEvent]=useState("")
  const [engage,setEngagae]=useState("")
  const [prize,setPrize]=useState("")
  const [watch,setWatch]=useState("")
  const [loading,setLoading]=useState(true)
  useEffect(()=>{
fetch(`${host}/api/ourstats`,{
  method:'get',
}).then(res=>res.json()).then(data=>{

  if(data.data){
      setEvent(data.data.stats[0].value)
      setEngagae(data.data.stats[1].value)
      setPrize(data.data.stats[2].value)
      setWatch(data.data.stats[3].value)
      setLoading(false)
  }
})

  },[])
  const updateHandler=()=>{
    if(event.trim()==""||engage.trim()==""||prize.trim()==""||watch.trim()==""){
      return
    }
   var data=[
      {
        title:"Events",
        value:event
      },
      {
        title:"Gamers Engaged",
        value:engage
      },
      {
        title:"USD Pool Prize",
        value:prize
      },
      {
        title:"Watch Hours",
        value:watch
      },
    ]
    fetch(`${host}/admin71/updateeventstats`,{
      method:"put",
      headers:{
        "Content-Type":'application/json',
        authorization:`Bearer ${token}`
      },
      body:JSON.stringify({
        data:data
      })

    }).then(res=>{
      if(res.status==200){
          dispatch(snackmsg("Stats Updated"))
          
      }
      else if(res.status==401){
          dispatch(snackmsg("Session Expired"))
          dispatch(logout())
      }
  }).catch(err=>{
      dispatch(snackmsg("Network Error"))
  })
  }
  return (
    <Container>
      <Title style={{ marginBottom: "10px" }} color="red">
        Our Stats
      </Title>
      {
        loading?<></>:
      <>
      <EventDatasContainer>
        <EventData>
          <Icon>
            <EventIcon />
          </Icon>
          <Text value={event} onChange={e=>setEvent(e.target.value)} type="text" required></Text>
          <EventTitle>Events</EventTitle>
        </EventData>
        <EventData>
          <Icon>
            <FavoriteBorderIcon/>
          </Icon>
          <Text value={engage} onChange={e=>setEngagae(e.target.value)}  type="text" required></Text>
          <EventTitle>GAMERS ENGAGGED</EventTitle>
        </EventData>
        <EventData>
          <Icon>
            <AttachMoneyIcon/>
          </Icon>
          <Text value={prize} onChange={e=>setPrize(e.target.value)} type="text" required></Text>
          <EventTitle>USD PRIZE POOL</EventTitle>
        </EventData>
        <EventData>
          <Icon>
            <CameraAltIcon/>
          </Icon>
          <Text value={watch} onChange={e=>setWatch(e.target.value)} type="text" required></Text>
          <EventTitle>Watch Hours</EventTitle>
        </EventData>
      </EventDatasContainer>
      <Btn onClick={updateHandler} type="submit" style={{ marginTop: "14px" }}>Update</Btn>
      </>
      }
    </Container>
  );
}

export default Data;
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;
const EventDatasContainer = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  padding: 10px 20px;
  background: linear-gradient(to right, red, blue);
`;
const EventData = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 88px;
    color: white;
    width: auto !important;
    /* @media(max-width:1440px){
  height:68px;
}
@media(max-width:1044px){
  height:55px;
}
@media(max-width:768px){
  height:45px;
}
@media(max-width:425px){
  height:45px;
}
@media(max-width:375px){
  height:39px;
} */
  }
`;
const Text = styled.input`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-top: 10px;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  background: transparent;
  outline: none;
  border: none;
  /* font-family: 'Montserrat',sans-serif; */
  /* @media (max-width:1440px){
    font-size:43px;
}
@media (max-width:1044px){
    font-size:35px;
}
@media (max-width:900px){
    font-size:26px;
}
@media (max-width:768px){
    font-size:22px;
}
@media (max-width:425px){
    font-size:18px;
}
@media (max-width:375px){
    font-size:16px;
} */
`;
const EventTitle = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 18px;

  text-transform: uppercase;
  position: relative;
  bottom: 15px;
  /* @media (max-width:1440px){
    font-size:15px;
}
@media (max-width:1044px){
    font-size:13px;
}
@media (max-width:900px){
    font-size:11.5px;
}
@media (max-width:768px){
    font-size:10px;
}
@media (max-width:425px){
    font-size:8.5px;
}
@media (max-width:375px){
    font-size:7.5px;
} */
`;
