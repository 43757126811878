import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Images from '../components/Image'
import { Btn, Title } from '../components/styles'
import TextBox from '../components/TextBox'
import DeleteIcon from '@material-ui/icons/Delete';
import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
import { logout, snackmsg } from '../actions/action'

function EventEdit(props) {
    const token=useSelector(state=>state.token)
    const [name,setName]=useState("")
    const [sname,setSname]=useState("")
    const [game,setGame]=useState("")
    const [status,setStatus]=useState("completed")
    const [short,setShort]=useState("")
    const [more,setMore]=useState("")
    const [reg,setReg]=useState("")
    const LogoRef=useRef(null)
    const ImageRef=useRef(null)
    const [src,setSrc]=useState('')
    const [src2,setSrc2]=useState('')
    const [images,setImages]=useState([])
    const dispatch=useDispatch()
    const unauthorized=()=>{
        dispatch(snackmsg("Session Expired"))
            dispatch(logout())
    }
    const addImage=()=>{
     
        if(ImageRef.current.files.length>0){
            const images=new FormData()
            images.append('event-image',ImageRef.current.files[0])
            fetch(`${host}/admin71/eventimage`,{
                method:"POST",
                headers:{
                    authorization:`Bearer ${token}`
                },
                body:images
            }).then(async(res)=>{
                if(res.status==200){
                    var result=await res.json()
                    setImages(images=>[...images,result.path])
                    ImageRef.current.value="";
                    ImageChangeHandler()
                }
                else if(res.status==401){
                    unauthorized()
                }
            }).catch(err=>{
                dispatch(snackmsg("Network Error!"))
            })
        }
    }
const LogoChangeHandler=()=>{
    if(LogoRef.current.files.length>0){
        setSrc(URL.createObjectURL(LogoRef.current.files[0]))
    }   
    else{
        setSrc("")
    }
}
const ImageChangeHandler=()=>{
    if(ImageRef.current.files.length>0){
        setSrc2(URL.createObjectURL(ImageRef.current.files[0]))
    }   
    else{
        setSrc2("")
    }
}
const removeImage=(src)=>{
    if(window.confirm("Do you want to remove Image?")){
        
        setImages(images=>images.filter(img=>img!=src))
    }
}

const submitHandler=()=>{
    if(!name.trim() || !game.trim()){
        return dispatch(snackmsg("Name and Game is Required"))
    }
    var body=new FormData()
    if(LogoRef.current.files.length>0){
        body.append('logo',LogoRef.current.files[0])
    }
    body.append('name',name)
    body.append('shortname',sname)
    body.append('game',game)
    body.append('status',status)
    body.append('description',more)
    body.append('shortdescription',short)
    body.append('images',JSON.stringify(images))
    body.append('link',reg)
    fetch(`${host}/admin71/addevent`,{
        method:"POST",
        body:body,
        headers:{
            authorization:`Bearer ${token}`
        }
    }).then(res=>{
        if(res.status==200){
            dispatch(snackmsg("Event Added Sucessfully!!"))
            props.history.push('/events')
        }
        else if(res.status==401){
            unauthorized()
        }
    }).catch(err=>{
        dispatch(snackmsg("Network Error"))
    })
    
}
    return (
        <Container>
             <Title style={{color:'red'}}>
              Add Event
            </Title>
            <Form>
        <FormElement>
            <label>Name</label>
            <input value={name} onChange={e=>setName(e.target.value)}  type="text" required></input>
        </FormElement>
        <FormElement width="30%">
            <label>Short Name</label>
            <input value={sname} onChange={e=>setSname(e.target.value)} type="text"></input>
        </FormElement>
        <FormElement width="30%">
            <label>Game</label>
            <input value={game} onChange={e=>setGame(e.target.value)} type="text"></input>
        </FormElement>
        <FormElement width="30%">
            <label>Status</label>
            <select value={status} onChange={e=>setStatus(e.target.value)}>
                <option value="completed">
                    Completed
                </option>
                <option value="upcoming">
                    Upcoming
                </option>
                <option value="ongoing">
                    Ongoing
                </option>
            </select>
        </FormElement>
        <FormElement >
            <label>Short Description</label>
            <TextBox value={short} onChange={setShort} height={220}/>
        </FormElement>
        <FormElement >
            <label>More Details</label>
            <TextBox value={more} onChange={setMore}/>
        </FormElement>
        <FormElement >
            <label>Registration Link</label>
            <input value={reg} onChange={e=>setReg(e.target.value)} type="text"></input>
        </FormElement>
        <FormElement>
            <label>Tournament Logo</label>
            <input onChange={LogoChangeHandler} accept="images/*" ref={LogoRef} type="file"/>
            
           <img width="130px" src={src} onError={()=>setSrc("")}>
            
           </img>
        </FormElement>
        <FormElement>
            <label>Images</label>
        <ImageContainer>
            {images.map((src,key)=>(
                <Images width="220px" src={`${host}${src}`} key={key} onError={e=>{e.target.style.display="none"}} >
                <DeleteButton onClick={()=>removeImage(src)}>
                    <DeleteIcon/>
                </DeleteButton>
                </Images>
                
            ))

            }
                
        </ImageContainer>
        <input onChange={ImageChangeHandler} accept="images/*" ref={ImageRef} type="file"/>
            <img width="130px" src={src2} onError={()=>setSrc2("")}></img>
            <Button onClick={addImage}>
                +Add
            </Button>
           

        </FormElement>
        <FormElement>
            <Btn onClick={submitHandler}>Submit</Btn>
        </FormElement>
            </Form>
        </Container>
    )
}
const Container=styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`

export default EventEdit
const Form=styled.form`
width: 100%;
padding:10px 14px;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`
const FormElement=styled.div`
display: flex;
flex-direction: column;
margin-top: 10px;
&:first-of-type{
    margin-top: 0;
}
width:${props=>props.width?props.width:"100%"};
input[type=text],select{
    padding:10px 5px;
    font-size:18px;
}
input[type=file]{
    padding:10px 5px;
    width: max-content;
}
`
const ImageContainer=styled.div`
width: 100%;
height: auto;
display: flex;
/* justify-content: space-between; */
margin:5px 8px;
flex-wrap: wrap;
`
const Button=styled.div`
padding:5px 8px;
color:white;
background: red;
cursor: pointer;
width: min-content;
border:1px solid;
`
const Images2=styled(Images)``
const DeleteButton=styled.div`
position: absolute;
width: 100%;
height: 100%;
display:flex;
align-items: center;
justify-content: center;
color:white;
cursor: pointer;
opacity: 0;
transition: .34s ease;
background: transparent;
&:hover{
opacity: 1;
background: #10101040;
}

`