import React from 'react'
import styled from 'styled-components'
import NotificationsIcon from '@material-ui/icons/Notifications';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import {Link, useHistory} from 'react-router-dom'
import ShowChartIcon from '@material-ui/icons/ShowChart';
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import {useDispatch} from 'react-redux' 
import WorkIcon from '@material-ui/icons/Work';
import {logout} from '../actions/action'
function Sidebar() {
    const history=useHistory()
    const dispatch=useDispatch()
    const logoutHandler=()=>{
        if(window.confirm("Do you want to logout?")){

            dispatch(logout())
        }
    //    await localStorage.removeItem('token')
    //    document.location='/'

    }
    return (
        <Container>
            <Brand>
            <Logo>
                <img src="/logo.png" alt="AELOGO"/>
            </Logo>
            <CompanyName>Arabpati Esports</CompanyName>
            </Brand>
            <NavItems to="/aboutus">
                <Icon>
                <InfoIcon/>
                </Icon>
                <Title>
                   About Us

                </Title>
            </NavItems>
            <NavItems to="/announcement">
                <Icon>
                <NotificationsIcon/>
                </Icon>
                <Title>
                    Announcement

                </Title>
            </NavItems>
            <NavItems to="/events">
                <Icon>
                <EventIcon/>
                </Icon>
                <Title>
                    Events

                </Title>
            </NavItems>
            <NavItems to='/team'>
                <Icon>
                <PeopleIcon/>
                </Icon>
                <Title>
                    Our Team

                </Title>
            </NavItems>
            <NavItems to="/data">
                <Icon>
                <ShowChartIcon/>
                </Icon>
                <Title>
                  Event Stats

                </Title>
            </NavItems>
            <NavItems to="/whatwedo">
                <Icon>
                <WorkIcon/>
                </Icon>
                <Title>
                  What We Do

                </Title>
            </NavItems>
            <NavItems onClick={logoutHandler}>
                <Icon>
                    <LockIcon/>
                
                </Icon>
                <Title>
                  Logout

                </Title>
            </NavItems>
          

        </Container>
    )
}

export default Sidebar
const Container=styled.div`
position: sticky;
top:0;
left:0;
height: 100vh;
background:#0a0e40;
width: 250px;
display: flex;
flex-direction: column;
color:white;
padding: 10px 0;
`
const Brand=styled.div`
height: 30px;
display: flex;
/* justify-content: center; */
align-items: center;
margin-bottom: 20px;
padding: 2px 10px;
`
const Logo=styled.div`
height: 100%;
width: 50px;
display: flex;
align-items: center;
margin-right: 8px;
img{
    display: block;
    height: 28px;
    width: auto;
    object-fit: contain;
}
`
const CompanyName=styled.div`
color: white;
font-size:20px;
font-weight:600;
`
const NavItems=styled(Link)`
height:40px;
display: flex;
align-items: center;
cursor: pointer;
color:white;
text-decoration:none;
margin-top: 0px;
padding: 2px 10px;
transition: .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
:hover{
    background:#4b7cb9;
}

`
const Icon=styled.span`
width: 50px;
display: flex;
align-items: center;
justify-content: center;
margin-right: 8px;

`
const Title=styled.span`
flex: 1;
display: block;
font-size:19px;

`