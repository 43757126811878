import Logins from "./pages/Login";
import {Switch,BrowserRouter as Router,Route} from 'react-router-dom'
import Sidebar from "./components/Sidebar";
import Announcement from "./pages/Announcement";
import Events from "./pages/Events";
import OurTeam from "./pages/OurTeam";
import Data from "./pages/Data";
import styled from "styled-components";
import EventAdd from "./pages/EventAdd";
import EventEdit from "./pages/EventEdit";
import About from "./pages/About";
import { useEffect, useState } from "react";
import {useSelector,useDispatch} from 'react-redux'
import {check,closesnack} from './actions/action'
import { IconButton, Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import React from 'react'
import WhatWeDo from "./pages/WhatWeDo";
let host="http://localhost:8000"

function App() {
  const Login=useSelector(state=>state.login)
  const loading=useSelector(state=>state.loading)
  const open=useSelector(state=>state.open)
  const message=useSelector(state=>state.message)

  const dispatch=useDispatch()
  const handleClose =()=>{
    dispatch(closesnack())
  }
  useEffect(()=>{
    dispatch(check())

  },[])
  
  return (
    <div className="App" >
<Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      
      {
loading?<>{"loading....."}</>:
<Router>
      <Container>
        {!Login?

        <Logins/>
        :
        <>
        
     <Sidebar>

</Sidebar>


<Route path='/announcement' component={Announcement} exact/>
<Route path='/events' component={Events} exact>
 </Route>
<Route path='/team' component={OurTeam} exact/>
<Route path='/data' component={Data} exact/>
<Route path='/addevent' component={EventAdd} type="add" exact/>
<Route path='/editevent/:id' component={EventEdit} type="add" exact/>
<Route path='/aboutus' component={About}  exact/>
<Route path='/whatwedo' component={WhatWeDo}  exact/>

        </>
        }

 
    
      </Container>
    </Router>
      }
    
    </div>
  );
}
const Container=styled.div`
width: 100%;
min-height: 100vh;
display: flex;


`
export default App;
