import reduxThunk from 'redux-thunk'
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import {CHECK_SUCESS,CHECK_FAIL, LOGOUT, LOGIN, MESSAGE, OPENSNACK, CLOSESNACK} from './actions/action'
const initialState={login:false,token:null,loading:true,open:false,message:''}

const reducer=(state=initialState,action)=>{

    switch(action.type){
        case CHECK_SUCESS:
            state.loading=false
            state.login=true
            state.token=action.token
            return state
        case CHECK_FAIL:
            state.loading=false
            return state
        case LOGOUT:
            state.login=false
            state.token=null
            return state
        case LOGIN:
            state.login=true
            state.token=action.token
            return state
        case MESSAGE:
            state.message=action.message
            state.open=true
            return state
       
        case CLOSESNACK:
            state.open=false
            return state
        default:
            return state
    }

}
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;   
const store=createStore(reducer,initialState,applyMiddleware(reduxThunk))
export default store;